import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Promise } from "bluebird";
import axios from "../../axios.js";
import { uploadService } from "./upload.service";
const PhotoContext = React.createContext();

const initialState = {
  photosList: [],
  photos: {
    "3x4": 0,
    "9x12": 0,
    "10x15": 0,
    "13x18": 0,
    "16x22": 0,
    "20x25": 0,
    "20x30": 0,
  },
  PHOTO_PRICES: {
    "3x4": 1000,
    "9x12": 400,
    "10x15": 400,
    "13x18": 800,
    "16x22": 1000,
    "20x25": 2500,
    "20x30": 3000,
  },
  defaultSize: "10x15",
  phoneNumber: "",
  totalPrice: 0,
  uploadedPhotos: {"finishPhoto": 0, "errorPhoto": 0, "loading": ""},
  getDatabaseSize:  {"download": false, "errorMsg": "", "statusCode": ""},
};

export const PhotoStore = (props) => {
  const [photo, setPhoto] = useState(initialState);
  //songoson zurgiin MASSIV
  const [zurgiinArray, setZurgiinArray] = useState([]);
  //Songosonii daraa ajillah Size viewer tsonh
  const [sizeViewer, setSizeViewer] = useState(false);

  //warning text
  const [visible, setVisible] = useState(false);
  const [warningText, setWarningText] = useState("");

  // success text
  const [successVisible, setSuccessVisible] = useState(false);
  const [successText, setSuccessText] = useState("");
  
  let history = useHistory();
  
  //zurag nemeh
  const addPhotos = (file, size) => {
    setPhoto((prevState) => ({
      ...photo,
      photosList: [...prevState.photosList, file],
      photos: {
        ...photo.photos,
        [size]: prevState.photos[size] + 1,
      },
      uploadedPhotos: {"finishPhoto": photo.uploadedPhotos['finishPhoto'], "errorPhoto": 0, "loading": ""},
      defaultSize: size,
      totalPrice: prevState.totalPrice + photo.PHOTO_PRICES[size],
    }));
  };
  //zurag hasah
  const removePhotos = (photoIndex, photoSize) => {
    console.log("removw photos");
  };

  //zurgand huvi nemeh
  const addPiece = (photoIndex, photoSize) => {
    let newPhotos = [...photo.photosList];
    //сонгосон зургийн сонгосон хэмжээг нэгээр нэмэгдүүлж байна.
    newPhotos[photoIndex]["pieces"][photoSize]
      ? newPhotos[photoIndex]["pieces"][photoSize]++
      : (newPhotos[photoIndex]["pieces"][photoSize] = 1);
    //ерөнхий утгууд дээр нэгээр нэмж байна.
    setPhoto({
      ...photo,
      photosList: newPhotos,
      photos: {
        ...photo.photos,
        [photoSize]: photo.photos[photoSize] + 1,
      },
      totalPrice: photo.totalPrice + photo.PHOTO_PRICES[photoSize],
    });
  };
  //zurgiin huvi hasaj bna
  const removePiece = (photoIndex, photoSize) => {
    let newRemovePhotos = [...photo.photosList];
    let newPieces = { ...photo.photos };

    if (newRemovePhotos[photoIndex]["pieces"][photoSize]) {
      //Сонгосон хэмжээг нэгээр хасах үйлдэл
      newRemovePhotos[photoIndex]["pieces"][photoSize]--;
      // нийт талбараас нэгээр хасах үйлдэл
      if (newRemovePhotos[photoIndex]["pieces"][photoSize] >= 0) {
        newPieces[photoSize]--;
        photo.totalPrice = photo.totalPrice - photo.PHOTO_PRICES[photoSize];
      }
    } else {
      newRemovePhotos[photoIndex]["pieces"][photoSize] = 0;
    }

    if (newRemovePhotos[photoIndex]["pieces"][photoSize] === 0) {
      //console.log(newPhotosList[photoIndex][1]);

      delete newRemovePhotos[photoIndex]["pieces"][photoSize];

      if (Object.keys(newRemovePhotos[photoIndex]["pieces"]).length === 0) {
        newRemovePhotos.splice(photoIndex, 1);
      }
    }

    setPhoto({
      ...photo,
      photosList: newRemovePhotos,

      photos: newPieces,
    });
  };

  //hemjee solih
  const changeSize = (photoSize) => {
    if(photo.photos[photoSize] === undefined) {
      photo.photos[photoSize] = 0;
    }
  
    setPhoto({
      ...photo,
      photos: photo.photos,
      defaultSize: photoSize,
    });
  };
  //zurag tseverleh
  const clearPhotos = () => {
    setPhoto(initialState);
  };

  const UploadPhotos = (orderNumber, phoneNumber, fileName) => {
    photo.uploadedPhotos['loading'] = "loading";
          setPhoto({
            ...photo,
            uploadedPhotos: photo.uploadedPhotos,
          });
    let fileList = [];
    let index = "";
    if (!fileName) {
      fileList = photo.photosList;
    } else {
      index = photo.photosList.findIndex((e) => e.name === fileName.name);
      fileList.push(photo.photosList[index]);
    }
    Upload(fileList, orderNumber, phoneNumber, photo.totalPrice, index);
  };

  var Upload = async (
    files,
    orderNumber,
    userPhone,
    totalPrice,
    oneFileIndex
  ) => {
    let size = "";
    let k = 0;
    const res = await Promise.map(
      files,
      async (file, index) => {
        const formPayload = new FormData();
        const newName = NerTootsooloh(oneFileIndex ? oneFileIndex : index, file.pieces)
        formPayload.append("photo", file);
        formPayload.append("phone", userPhone);
        formPayload.append("order", orderNumber);
        formPayload.append("total_price", totalPrice);
        formPayload.append("photo_index", oneFileIndex ? oneFileIndex : index);
        formPayload.append("photo_name", newName);
        var lastNow = new Date().getTime();
        var lastKBytes = 0;

        if (!file.loading.finished) {
          await axios
            .post("/api/upload", formPayload, {
              onUploadProgress: (progress) => {
                LoadingPhoto(
                  oneFileIndex ? oneFileIndex : index,
                  ProgressTootsooloh(progress, lastNow, lastKBytes)
                );
              },
            })
            .then((response) => {
              let res = response.data;
              FinishedPhoto(
                oneFileIndex ? oneFileIndex : res.index,
                res.name,
                "finished"
              );
            })
            .catch((error) => {
                if (error.response) {
                FinishedPhoto(
                  oneFileIndex ? oneFileIndex : index,
                  file['name'],
                  'error',
                  error.response.data.errors ? error.response.data.errors : error.response.data.message 
                );
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
                FinishedPhoto(
                  oneFileIndex ? oneFileIndex : index,
                  file['name'],
                  'error',
                  error.error.message 
                );
              }
            });
        }
        return true;
      },
      { concurrency: 5 }
    );
  };

  const NerTootsooloh = (index, pieces) => {
    let newName = `${index + 1}.`;
    Object.keys(pieces).map((el) => {
      newName += `${el}-${pieces[el]} `;
    });
    return newName;
  };

  const ProgressTootsooloh = (progress, lastNow, lastKBytes) => {
    const { loaded, total, lengthComputable } = progress;
    if (lengthComputable) {
      var now = new Date().getTime();
      var bytes = loaded;
      var percent = (bytes / total) * 100;
      var kbytes = bytes / 1024;
      var mbytes = kbytes / 1024;
      var uploadedkBytes = kbytes - lastKBytes;
      var elapsed = (now - lastNow) / 1000;
      var kbps = elapsed ? uploadedkBytes / elapsed : 0;
      lastKBytes = kbytes;
      lastNow = now;
    }
    let fileHemjee = mbytes;
    let Speed = kbps;
    fileHemjee =
      fileHemjee < 1 ? `${kbytes.toFixed(2)} KB` : `${mbytes.toFixed(2)} MB`;
    Speed =
      Speed > 1000
        ? (kbps / 1000).toFixed(2) + "MB/s"
        : `${kbps.toFixed(2)} KB/s`;

    let progressTootsoo = [];

    progressTootsoo["mbytes"] = fileHemjee;
    progressTootsoo["percent"] = `${percent.toFixed(2)} %`;
    progressTootsoo["kbps"] = Speed;
    return progressTootsoo;
  };
  const FinishedPhoto = (photoIndex, fileName, msg, error) => {
    let newPhotos = [...photo.photosList];

    if (newPhotos[photoIndex].name === fileName) {
      newPhotos[photoIndex]["loading"]["finished"] = msg;
      photo.uploadedPhotos['finishPhoto'] = photo.uploadedPhotos['finishPhoto'] + 1;
      newPhotos[photoIndex]["loading"]["error"] = error;
      if(error) photo.uploadedPhotos['errorPhoto'] = photo.uploadedPhotos['errorPhoto'] + 1;
      //бүх зураг хуулж дуусахад allPhotoFinished болно
      if(newPhotos.length === photo.uploadedPhotos['finishPhoto']){
          photo.uploadedPhotos['loading'] = "allPhotoFinished";
          setPhoto({
            ...photo,
            uploadedPhotos: photo.uploadedPhotos,
          });
          
        };
      setPhoto({
        ...photo,
        uploadedPhotos: photo.uploadedPhotos,
        photosList: newPhotos,
      });
    }
  };

  const LoadingPhoto = (photoIndex, percent) => {
    let newPhotos = [...photo.photosList];
    newPhotos[photoIndex]["loading"] = percent;
    newPhotos[photoIndex]["loading"]["finished"] = "loading";
    setPhoto({
      ...photo,
      photosList: newPhotos,
    });
  };
  
  const getSize = () =>{
  const menus = {};
  const download = {}
   return axios.get(`/api/sizes/`).then((result) => {
      result.data.map((el) => {
        menus[el['name']] =  el['price'];     
      })
      download['download'] = true;
      setPhoto({
        ...photo,
        PHOTO_PRICES: menus,
        getDatabaseSize: download,
      });       
    }).catch((e) =>{
      download['download'] = false;
      download['errorMsg'] = "Сервэрээс өгөгдөл татахад алдаа гарлаа."
      download['statusCode'] = e.response.status;
      setPhoto({
        ...photo,
        getDatabaseSize: download,
      });
      history.push("/error");
    });
  }
  
  function zurgaarDuurgeh(files) {
    const { defaultSize, photosList } = photo; // jishee ni 10x15
   // let size = "";

    //zurgaa hadalah function
    function addFileList(file, thumbFile, size) {
      // songogdson size
      file["pieces"] = [];
      if (size) file["pieces"][size] = 1;
      else file[defaultSize] = 1;

      file["loading"] = [];
      file["thumb"] = thumbFile;

      addPhotos(file, size);
    }

    function filesToArray(songosonZurag, size) {
      let jagsaaladOrsonZurag = "";

      var found = songosonZurag.find(function (element) {
        const index = photosList.findIndex((e) => e.name === element.name);
        if (index === -1) {
          let createThumb = URL.createObjectURL(element);
          addFileList(element, createThumb, size);
          setSuccessVisible(true);
          setTimeout(
            function () {
              onDismiss2();
            }.bind(this),
            5000
          );
          setSuccessText(`Таны зураг амжилттай нэмэгдлээ.`);
        } else {
          let createThumb = URL.createObjectURL(element);
          jagsaaladOrsonZurag += `<li><a href="${createThumb}" class="alert-link">
          ${element.name}
        </a></li>`;
          setWarningText(
            `<ol class="list-style: decimal inside;">${jagsaaladOrsonZurag}</ol> зураг жагсаалтад орсон байна.`
          );
          setVisible(true);
        }
      });
    }
    
    const onDismiss = (t) => {
      setVisible(false);
      setWarningText("");
    };
    const onDismiss2 = (t) => {
      setSuccessVisible(false);
      setSuccessText("");
    };
    
    return {
      zurgiinHemjeeSolih: function (hemjee) {
        //зураг сонгох хэсийг хаах
        setSizeViewer(false);
        if (zurgiinArray) {
          filesToArray(zurgiinArray, hemjee);
        }
      },
      zuragOruulah: function (songosonZurag) {
        //зураг сонгох хэсэг ажиллуулах
        setSizeViewer(true);
        if (songosonZurag) {
          setZurgiinArray(songosonZurag);
        }
      },
    };
  }
  
  return (
    <PhotoContext.Provider
      value={{
        photo,
        sizeViewer,
        visible,
        warningText,
        successVisible,
        successText,
        addPhotos,
        addPiece,
        removePiece,
        changeSize,
        clearPhotos,
        UploadPhotos,
        LoadingPhoto,
        getSize,
        zurgaarDuurgeh,
      }}
    >
      {props.children}
    </PhotoContext.Provider>
  );
};
export default PhotoContext;
