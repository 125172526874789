import axios from "axios";

const instance = axios.create({
    baseURL: "http://myphoto.mn/backend/server.php",

    //baseURL: "http://localhost:8000/",
    /*  headers: {
     'Access-Control-Allow-Methods': 'POST', 
     'Access-Control-Max-Age': '86400'
     },
     timeout: 15 * 60 * 1000, */
});

export default instance;